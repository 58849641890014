import React, { useState } from "react"
import { Box, Link, Text } from "@chakra-ui/react"

import FilterBar from "./FilterBar"
import Forschungsschwerpunkte from "./Forschungsschwerpunkte"
import ForschungsResults from "./ForschungsResults"
import { useSelector } from "react-redux"

const ForschungsFilter = ({ posts, institute, forschung, stringData }) => {
  const [selectedFilterArray, setSelectedFilterArray] = useState([])

  //** Select grid or list View */
  const [listView, setListView] = useState("grid")

  //** Sortieren nach */
  const [sortBy, setSortBy] = useState({})

  //** Suche nach */
  const [search, setSearch] = useState("")

  const CURRENT_LANG = useSelector(state => state.currentLang)
  return (
    <Box>
      {selectedFilterArray.length === 0 && search === "" ? (
        ""
      ) : (
        <Box mb="24px">
          <Link
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            onClick={() => {
              setSearch("")
              setSelectedFilterArray([])
            }}
          >
            <Text className="backLink">
              {CURRENT_LANG === "en" ? "Back" : "Zurück"}
            </Text>
          </Link>
        </Box>
      )}
      <FilterBar
        selectedFilterArray={selectedFilterArray}
        setSelectedFilterArray={setSelectedFilterArray}
        listView={listView}
        setListView={setListView}
        sortBy={sortBy}
        setSortBy={setSortBy}
        search={search}
        setSearch={setSearch}
        forschung={forschung}
        stringData={stringData}
      />
      {/* CONTENT */}
      {selectedFilterArray.length === 0 && search === "" ? (
        <Forschungsschwerpunkte
          stringData={stringData}
          schwerpunkte={posts}
          institute={institute}
          forschung={forschung}
        />
      ) : (
        <ForschungsResults
          allForschung={forschung}
          selectedFilterArray={selectedFilterArray}
          listView={listView}
          sortBy={sortBy}
          search={search}
          stringData={stringData}
        />
      )}
    </Box>
  )
}

export default ForschungsFilter
