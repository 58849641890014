import React from "react"

import { Flex, ScaleFade, Text } from "@chakra-ui/react"

import ForschungsCardList from "../Contents/ForschungsCardList"

const ListList = ({ results, stringData }) => {
  // console.log("RESULTS LIST", results, stringData)
  return (
    <Flex width="100%" direction="column" mt="16px">
      {results.map((forschung, index) => {
        return (
          <ScaleFade initialScale={0.9} in={true}>
            <ForschungsCardList
              key={forschung?.title + index}
              forschung={forschung}
              slug={forschung?.slug}
              stringData={stringData}
            />
          </ScaleFade>
        )
      })}
      {results.length === 0 ? (
        <Text fontWeight="bold" width="100%" my="16px" textAlign="center">
          {stringData.acf_filter_settings.keineForschungsprojekteGefunden}
        </Text>
      ) : (
        ""
      )}
    </Flex>
  )
}

export default ListList
