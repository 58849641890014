import React from "react"

import { Box, Stack } from "@chakra-ui/react"

import FilterMenu from "./FilterMenu"

const FilterSelects = ({
  selectedFilterArray,
  setSelectedFilterArray,
  schwerpunktthemen,
  keywords,
  years,
  names,
  disciplines,
  categories,
  stringData,
}) => {
  return (
    <Box mt="4" rounded="md" shadow="md">
      <Stack
        direction={["column", "column", "column", "row", "row"]}
        wrap={["wrap", "wrap", "wrap", "wrap", "inherit", "inherit"]}
        pb="3px"
      >
        <FilterMenu
          name={stringData.schwerpunktthema}
          id="Schwerpunktthema"
          items={schwerpunktthemen}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
        <FilterMenu
          name={stringData.keyword}
          id="Keyword"
          items={keywords}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
        <FilterMenu
          name={stringData.jahr}
          id="Jahr"
          items={years}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
        <FilterMenu
          name={stringData.name}
          id="Name"
          items={names}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
        <FilterMenu
          name={stringData.disziplin}
          id="Disziplin"
          items={disciplines}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
        <FilterMenu
          name={stringData.kategorie}
          id="Kategorie"
          items={categories}
          selectedFilterArray={selectedFilterArray}
          setSelectedFilterArray={setSelectedFilterArray}
        />
      </Stack>
    </Box>
  )
}

export default FilterSelects
