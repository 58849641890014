// 🔍 FILTER UND SUCHE

export const filterForschung = (forschung, filterValues, searchValue, sort) => {
  // Suche alles mit "Mob"
  // && wo
  // Schwerpunktthema 1 + Schwerpunktthema 2
  // && miz
  // Keyword 1 + Keyword 2
  // && in den Jahren
  // Jahr 1 + Jahr 2
  // && den Kategoruen
  // Kategorie 1 + Kategorie 2
  // && den Namen
  // Name1 + Name2
  // && den Disziplinen
  // Disziplin 1 + Disziplin 2

  let schwerpunkte = filterValues.filter(
    filter => filter.name === "Schwerpunktthema"
  )
  let keywords = filterValues.filter(filter => filter.name === "Keyword")
  let jahre = filterValues.filter(filter => filter.name === "Jahr")
  let kategorien = filterValues.filter(filter => filter.name === "Kategorie")
  let namen = filterValues.filter(filter => filter.name === "Name")
  let disziplinen = filterValues.filter(filter => filter.name === "Disziplin")

  let includesOneOfSchwerpunkt = schwerpunkte.length === 0 ? true : false
  let includesOneOfKeyword = keywords.length === 0 ? true : false
  let includesOneOfJahr = jahre.length === 0 ? true : false
  let includesOneOfKategorie = kategorien.length === 0 ? true : false
  let includesOneOfNamen = namen.length === 0 ? true : false
  let includesOneOfDisziplinen = disziplinen.length === 0 ? true : false

  // SEARCH
  let searchedForschung = searchForschung(forschung, searchValue)

  // FILTER
  let filteredForschung = searchedForschung.filter(projekt => {
    includesOneOfSchwerpunkt = filterSchwerpunktthema(projekt, schwerpunkte)
    includesOneOfKeyword = filterKeyword(projekt, keywords)
    includesOneOfJahr = filterJahr(projekt, jahre)
    includesOneOfKategorie = filterKategorie(projekt, kategorien)
    includesOneOfNamen = filterName(projekt, namen)
    includesOneOfDisziplinen = filterDisziplin(projekt, disziplinen)

    return (
      includesOneOfSchwerpunkt &&
      includesOneOfKeyword &&
      includesOneOfJahr &&
      includesOneOfKategorie &&
      includesOneOfNamen &&
      includesOneOfDisziplinen
    )
  })

  // console.log("SORT", sort)
  const sortedForschung = sortBy(filteredForschung, sort)

  return sortedForschung
}

// 🪣 Filter

const searchForschung = (forschung, searchValue) => {
  // console.log("FOSCHUNGS SUCHE", forschung, searchValue)
  forschung = forschung.filter(projekt => {
    const searchString = `
  ${projekt?.title} ${
      forschung?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
        ?.name
    } 
    ${
      forschung?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
        ?.tax_translation?.enTranslation
    } 
   ${projekt?.acf_forschung?.groupProjektbeteiligte?.textAutoren?.map(
     autor => autor.name
   )}
  ${projekt?.acf_forschung?.groupProjektbeteiligte?.textInstitution}
  ${projekt?.acf_forschung?.groupProjektbeteiligte?.disziplin?.map(
    disziplin => disziplin.name
  )}
  ${projekt?.acf_forschung?.groupProjektbeteiligte?.disziplin?.map(
    disziplin => disziplin.tax_translation?.enTranslation
  )}
  ${projekt?.acf_forschung?.groupSuchrelevanteEintrage?.keyword?.map(
    keyword => keyword.name
  )} 
  ${projekt?.acf_forschung?.groupSuchrelevanteEintrage?.keyword?.map(
    keyword => keyword.tax_translation?.enTranslation
  )} 
  ${projekt?.acf_forschung?.links?.map(links => links.name)} 
  ${projekt?.acf_forschung?.projektbeschreibung?.textProjektbeschreibungText} 
  ${projekt?.acf_forschung?.projektbeschreibung?.textProjektbeschreibungTitle} 
  ${projekt?.acf_forschung?.relationSchwerpunktthema?.map(
    schwerpunkt => schwerpunkt.title
  )} 
  ${projekt?.acf_forschung?.groupSuchrelevanteEintrage?.year} 
  `
    return searchString
      .replace(/ß/g, "ss")
      .toLowerCase()
      .includes(searchValue.replace(/ß/g, "ss").toLowerCase())
  })

  return forschung
}

const filterSchwerpunktthema = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      if (
        filter.value ===
        forschungsProjekt?.acf_forschung?.relationSchwerpunktthema[0].title
      ) {
        includes = true
      }
    })
  } else {
    includes = true
  }
  return includes
}

const filterKeyword = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      forschungsProjekt?.acf_forschung?.groupSuchrelevanteEintrage?.keyword?.forEach(
        keyword => {
          if (
            filter.value === keyword.name ||
            filter.value === keyword.tax_translation?.enTranslation
          ) {
            includes = true
          }
        }
      )
    })
  } else {
    includes = true
  }
  return includes
}

const filterJahr = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      if (
        filter.value ===
        forschungsProjekt?.acf_forschung?.groupSuchrelevanteEintrage?.year
      ) {
        includes = true
      }
    })
  } else {
    includes = true
  }
  return includes
}

const filterName = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      forschungsProjekt?.acf_forschung?.groupProjektbeteiligte?.textAutoren?.forEach(
        autor => {
          if (autor.name === filter.value) {
            includes = true
          }
        }
      )
    })
  } else {
    includes = true
  }
  return includes
}

const filterDisziplin = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      forschungsProjekt?.acf_forschung?.groupProjektbeteiligte?.disziplin?.forEach(
        disziplin => {
          if (
            filter.value === disziplin.name ||
            filter.value === disziplin?.tax_translation?.enTranslation
          ) {
            includes = true
          }
        }
      )
    })
  } else {
    includes = true
  }
  return includes
}

const filterKategorie = (forschungsProjekt, filterItems) => {
  let includes = false
  if (filterItems.length !== 0) {
    filterItems.forEach(filter => {
      if (
        filter.value ===
          forschungsProjekt?.acf_forschung?.groupSuchrelevanteEintrage
            ?.anhangKategorie?.name ||
        filter.value ===
          forschungsProjekt?.acf_forschung?.groupSuchrelevanteEintrage
            ?.anhangKategorie?.tax_translation?.enTranslation
      ) {
        includes = true
      }
    })
  } else {
    includes = true
  }
  return includes
}

// Sort

const sortBy = (filteredForschung, sort) => {
  switch (sort.id) {
    case "az":
      return filteredForschung.sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })

    case "newFirst":
      return filteredForschung.sort((a, b) => {
        if (
          a.acf_forschung?.groupSuchrelevanteEintrage?.year <
          b.acf_forschung?.groupSuchrelevanteEintrage?.year
        ) {
          return 1
        }
        if (
          a.acf_forschung?.groupSuchrelevanteEintrage?.year >
          b.acf_forschung?.groupSuchrelevanteEintrage?.year
        ) {
          return -1
        }
        return 0
      })

    case "oldFirst":
      filteredForschung.reverse()
      return filteredForschung.sort((a, b) => {
        if (a.acf_forschung?.groupSuchrelevanteEintrage?.year === null) {
          return 1
        }
        if (b.acf_forschung?.groupSuchrelevanteEintrage?.year === null) {
          return -1
        }
        if (
          a.acf_forschung?.groupSuchrelevanteEintrage?.year <
          b.acf_forschung?.groupSuchrelevanteEintrage?.year
        ) {
          return -1
        }
        if (
          a.acf_forschung?.groupSuchrelevanteEintrage?.year >
          b.acf_forschung?.groupSuchrelevanteEintrage?.year
        ) {
          return 1
        }
        return 0
      })

    default:
      return filteredForschung
  }
}

// ⛑ Helper Function

/**
 *
 * @param {*} forschung
 * @param {*} schwerpunkt
 * @param {*} sort
 * @returns
 */
export const filterForschungSchwerpunkt = (forschung, schwerpunkt, sort) => {
  forschung = forschung.filter(projekt => {
    let isInSchwerpunkt = false
    projekt.acf_forschung.relationSchwerpunktthema?.forEach(
      projektSchwerpunt => {
        if (projektSchwerpunt.title === schwerpunkt) {
          isInSchwerpunkt = true
        }
      }
    )
    return isInSchwerpunkt
  })
  return forschung
}

/**
 *
 * @param {*} array
 * @param {*} value
 * @param {*} key
 * @returns {boolean} includes
 */
export const arrayIncludesObject = (array, value, key) => {
  let includes = false
  array.forEach(item => {
    if (item[key] === value) {
      includes = true
    }
  })
  return includes
}

export const getSchwerpunkte = forschung => {
  let schwerpunktArray = []
  forschung.forEach(projekt => {
    // TODO: Wenn kein Schwerpunkt gesetzt dann irgendwas anderes ???
    if (
      projekt?.acf_forschung?.relationSchwerpunktthema &&
      !schwerpunktArray.includes(
        projekt?.acf_forschung?.relationSchwerpunktthema[0]?.title
      )
    ) {
      schwerpunktArray = [
        ...schwerpunktArray,
        projekt?.acf_forschung?.relationSchwerpunktthema[0]?.title,
      ]
    }
  })
  return schwerpunktArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}

export const getKeywords = (forschung, locale) => {
  let keywordArray = []
  forschung.forEach(projekt => {
    projekt?.acf_forschung?.groupSuchrelevanteEintrage?.keyword?.forEach(
      keyword => {
        if (locale === "de_DE") {
          if (!keywordArray.includes(keyword?.name)) {
            keywordArray = [...keywordArray, keyword?.name]
          }
        } else {
          if (!keywordArray.includes(keyword?.tax_translation?.enTranslation)) {
            keywordArray = [
              ...keywordArray,
              keyword?.tax_translation?.enTranslation,
            ]
          }
        }
      }
    )
  })
  return keywordArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}

export const getYears = forschung => {
  let yearsArray = []
  forschung.forEach(projekt => {
    if (
      projekt?.acf_forschung?.groupSuchrelevanteEintrage?.year &&
      !yearsArray.includes(
        projekt?.acf_forschung?.groupSuchrelevanteEintrage?.year
      )
    ) {
      yearsArray = [
        ...yearsArray,
        projekt?.acf_forschung?.groupSuchrelevanteEintrage?.year,
      ]
    }
  })
  return yearsArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}

export const getNames = forschung => {
  let namesArray = []
  forschung.forEach(projekt => {
    let autoren = projekt?.acf_forschung?.groupProjektbeteiligte?.textAutoren
    // console.log(autoren)
    autoren.forEach(autor => {
      if (autor && !namesArray.includes(autor.name)) {
        namesArray = [...namesArray, autor.name]
      }
    })
  })
  return namesArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}

export const getDisciplines = (forschung, locale) => {
  let disciplineArray = []
  forschung.forEach(projekt => {
    projekt?.acf_forschung?.groupProjektbeteiligte?.disziplin?.forEach(
      disziplin => {
        if (locale === "de_DE") {
          if (!disciplineArray.includes(disziplin?.name)) {
            disciplineArray = [...disciplineArray, disziplin?.name]
          }
        } else {
          if (
            !disciplineArray.includes(disziplin?.tax_translation?.enTranslation)
          ) {
            disciplineArray = [
              ...disciplineArray,
              disziplin?.tax_translation?.enTranslation,
            ]
          }
        }
      }
    )
  })
  return disciplineArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}

export const getCategories = (forschung, locale) => {
  let categoriesArray = []
  forschung.forEach(projekt => {
    if (locale === "de_DE") {
      if (
        projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
          ?.name &&
        !categoriesArray.includes(
          projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
            ?.name
        )
      ) {
        categoriesArray = [
          ...categoriesArray,
          projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
            ?.name,
        ]
      }
    } else {
      if (
        projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
          ?.tax_translation?.enTranslation &&
        !categoriesArray.includes(
          projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
            ?.tax_translation?.enTranslation
        )
      ) {
        categoriesArray = [
          ...categoriesArray,
          projekt?.acf_forschung?.groupSuchrelevanteEintrage?.anhangKategorie
            ?.tax_translation?.enTranslation,
        ]
      }
    }
  })
  return categoriesArray.sort((a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })
}
