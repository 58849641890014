import React from "react"

import { Box, ScaleFade, Flex, Text } from "@chakra-ui/react"

import ForschungsCard from "../Contents/ForschungsCard"

const ListGrid = ({ results, stringData }) => {
  return (
    <Flex
      flexWrap="wrap"
      ml={["0px", "0px", "0px", "0px", "0px"]}
      justifyContent={[
        "center",
        "center",
        "center",
        "flex-start",
        "flex-start",
      ]}
      mt="16px"
    >
      {results.map((forschung, index) => {
        return (
          <Box
            key={forschung?.title + index}
            width={["100%", "100%", "50%", "50%", "33%"]}
            minWidth={["320px"]}
            maxWidth={["416px"]}
            px={["16px", "16px"]}
            py={["16px", "24px"]}
          >
            <ScaleFade initialScale={0.9} in={true}>
              <ForschungsCard
                imageFeaturedImage={forschung?.acf_forschung?.imageFeatureImage}
                schwerpunktthema={
                  forschung?.acf_forschung?.relationSchwerpunktthema !== null
                    ? forschung?.acf_forschung?.relationSchwerpunktthema[0]
                        ?.title
                    : ""
                }
                title={forschung?.title}
                textSummary={
                  forschung?.acf_forschung?.projektbeschreibung
                    ?.textProjektbeschreibungText
                }
                disziplin={
                  forschung?.acf_forschung?.groupProjektbeteiligte?.disziplin
                }
                // uri={`/wissenschaft/${forschung?.slug}/`}
                slug={forschung?.slug}
                // uri={() =>
                //   genUrl(forschung?.slug, "wissenschaft", CURRENT_LOCALE)
                // }
                onColor={true}
              />
            </ScaleFade>
          </Box>
        )
      })}

      {results.length === 0 ? (
        <Text fontWeight="bold" width="100%" my="16px" textAlign="center">
          {stringData.acf_filter_settings.keineForschungsprojekteGefunden}
        </Text>
      ) : (
        ""
      )}
    </Flex>
  )
}

export default ListGrid
