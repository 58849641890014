import React from "react"
import { Box, Heading, Link, Text } from "@chakra-ui/react"
import { cleanStringForAnchor } from "../../tools/helper"
import Forschungsschwerpunkt from "../Forschung/Forschungsschwerpunkt"
import { useSelector } from "react-redux"

const Forschungsschwerpunkte = ({
  schwerpunkte,
  institute,
  forschung,
  stringData,
}) => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  return (
    <Box mt="64px">
      {schwerpunkte.map((schwerpunkt, index) => (
        <Forschungsschwerpunkt
          slug={schwerpunkt?.slug}
          forschung={forschung}
          stringData={stringData}
          index={index}
          schwerpunkt={schwerpunkt}
          key={index}
        />
      ))}

      <Heading
        as="h3"
        size="md"
        color="modeGray.500"
        mt="128px"
        mb="24px"
        id={`${cleanStringForAnchor("Labs & Institute der Forschungspartner")}`}
      >
        {stringData.acf_research_settings.forschungspartnerTitel}
      </Heading>

      <Box width={["100%", "100%", "100%", "75%"]} maxWidth="920px">
        {institute.map((institut, index) => (
          <Box key={institut + index} mb="64px">
            <Heading
              as="h4"
              size="xl"
              mb="8px"
              // id={`${cleanStringForAnchor(
              //   CURRENT_LOCALE === "en_US"
              //     ? institut.acf_institute.enTranslation?.name
              //     : institut.name
              // )}`}
              id={institut.slug}
            >
              {CURRENT_LOCALE === "en_US"
                ? institut.acf_institute.enTranslation?.name
                : institut.name}
            </Heading>
            <Text mb="8px">
              {CURRENT_LOCALE === "en_US"
                ? institut.acf_institute.enTranslation?.beschreibung
                : institut.description}
            </Text>
            {institut.acf_institute?.link ? (
              <Link
                href={institut.acf_institute?.link}
                alt={institut.name}
                fontWeight="bold"
                fontSize="lg"
                className="menuLink"
                target="_blank"
                rel="noreferrer noopener"
              >
                {stringData.acf_research_settings.linkText}
              </Link>
            ) : (
              ""
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Forschungsschwerpunkte
