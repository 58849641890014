import React, { useState } from "react"
import { Stack, Input, IconButton } from "@chakra-ui/react"
import IconSearch from "../Icons/IconSearch"

const Suche = ({ setSearch, stringData }) => {
  const [tempSearch, setTempSearch] = useState("")
  return (
    <Stack direction="row">
      <Input
        placeholder={stringData.suche}
        value={tempSearch}
        onChange={event => {
          setTempSearch(event.target.value)
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            // console.log("do validate")
            setSearch(tempSearch)
          }
        }}
      />
      <IconButton
        colorScheme="gray"
        borderRadius="full"
        padding="0px"
        aria-label="Suche / Search"
        icon={<IconSearch className="w-5" />}
        onClick={() => {
          setSearch(tempSearch)
        }}
      />
    </Stack>
  )
}

export default Suche
