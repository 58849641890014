import React from "react"

import { Box, Heading, Link, Text } from "@chakra-ui/react"

import { cleanStringForAnchor } from "../../tools/helper"
import Relationslider from "../Contents/Relationslider/Relationslider"

import { filterForschungSchwerpunkt } from "../../tools/filterForschung"
import { useSelector } from "react-redux"
import useGenUrl from "../../hooks/useGenUrl"

const Forschungsschwerpunkt = ({
  schwerpunkt,
  institute,
  forschung,
  stringData,
  index,
  slug,
}) => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)
  let url = useGenUrl(slug, "wissenschaftsschwerpunkt", CURRENT_LOCALE)
  return (
    <Box key={schwerpunkt + index} mb="64px">
      <Box width={["100%", "100%", "100%", "75%"]} maxWidth="920px">
        <Box mb="24px">
          <Heading
            as="h3"
            size="md"
            color="modeGray.500"
            mb="8px"
            id={
              schwerpunkt.title != null
                ? `${cleanStringForAnchor(schwerpunkt.title)}`
                : ""
            }
          >
            {stringData.acf_research_settings.textTitelForschungsschwerpunkt}
          </Heading>

          <Heading as="h2" size="xl" mb="8px" maxWidth="920px" lineHeight="1.2">
            {schwerpunkt.title}
          </Heading>
          <Text mb="8px">
            {schwerpunkt.acf_forschungsschwerpunkt.textBeschreibung}
          </Text>
          <Link
            // to={`/wissenschaftsschwerpunkt/${schwerpunkt.slug}/`}
            href={url}
            alt={schwerpunkt.title}
            fontWeight="bold"
            fontSize="lg"
            className="menuLink"
          >
            {stringData.acf_research_settings.linkText}
          </Link>
        </Box>
        {/* REFERENCE MODULE */}
        <Box width={["100%", "100%", "100%", "75%"]} maxWidth="640px">
          <Relationslider
            relatedProjects={filterForschungSchwerpunkt(
              forschung,
              schwerpunkt.title,
              "newFirst"
            )}
            relationTitle={""}
            forschung={true}
            smallWidth={false}
            onColor={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Forschungsschwerpunkt
