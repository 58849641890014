import React from "react"

const IconViewGrid = props => {
  return (
    <svg
      className={props.className}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
    >
      <path
        d="M7.5,5C7.8,5,8,5.2,8,5.5v2C8,7.8,7.8,8,7.5,8h-2C5.2,8,5,7.8,5,7.5v-2C5,5.2,5.2,5,5.5,5H7.5 M7.5,4h-2C4.7,4,4,4.7,4,5.5
	v2C4,8.3,4.7,9,5.5,9h2C8.3,9,9,8.3,9,7.5v-2C9,4.7,8.3,4,7.5,4z"
      />
      <path
        d="M14.5,5C14.8,5,15,5.2,15,5.5v2C15,7.8,14.8,8,14.5,8h-2C12.2,8,12,7.8,12,7.5v-2C12,5.2,12.2,5,12.5,5H14.5 M14.5,4h-2
	C11.7,4,11,4.7,11,5.5v2C11,8.3,11.7,9,12.5,9h2C15.3,9,16,8.3,16,7.5v-2C16,4.7,15.3,4,14.5,4z"
      />
      <path
        d="M14.5,12c0.3,0,0.5,0.2,0.5,0.5v2c0,0.3-0.2,0.5-0.5,0.5h-2c-0.3,0-0.5-0.2-0.5-0.5v-2c0-0.3,0.2-0.5,0.5-0.5H14.5 M14.5,11
	h-2c-0.8,0-1.5,0.7-1.5,1.5v2c0,0.8,0.7,1.5,1.5,1.5l0,0h2c0.8,0,1.5-0.7,1.5-1.5l0,0v-2C16,11.7,15.3,11,14.5,11z"
      />
      <path
        d="M7.5,12C7.8,12,8,12.2,8,12.5v2C8,14.8,7.8,15,7.5,15h-2C5.2,15,5,14.8,5,14.5v-2C5,12.2,5.2,12,5.5,12H7.5 M7.5,11h-2
	C4.7,11,4,11.7,4,12.5v2C4,15.3,4.7,16,5.5,16l0,0h2C8.3,16,9,15.3,9,14.5l0,0v-2C9,11.7,8.3,11,7.5,11z"
      />
    </svg>
  )
}

export default IconViewGrid
