import React from "react"

import { Box, Button, Stack } from "@chakra-ui/react"

import { SmallCloseIcon, DeleteIcon } from "@chakra-ui/icons"

const SelectedFilter = ({
  selectedFilterArray,
  setSelectedFilterArray,
  stringData,
}) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      mt={selectedFilterArray.length === 0 ? "" : "0px"}
    >
      {selectedFilterArray.map((item, index) => {
        return (
          <Box key={item.value + index} pt="8px">
            <Button
              rightIcon={<SmallCloseIcon />}
              onClick={() => {
                setSelectedFilterArray(
                  [...selectedFilterArray].filter(element => {
                    return element.value !== item.value
                  })
                )
              }}
              marginInlineStart="0px"
            >
              {item.value}
            </Button>
          </Box>
        )
      })}
      {selectedFilterArray.length === 0 ? (
        ""
      ) : (
        <Box pt="8px">
          <Button
            colorScheme="gray"
            borderRadius="full"
            leftIcon={<DeleteIcon />}
            onClick={() => {
              setSelectedFilterArray([])
            }}
          >
            {stringData.alleFilterEntfernen}
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default SelectedFilter
