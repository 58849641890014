import React from "react"

const IconViewList = props => {
  return (
    <svg
      className={props.className}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
    >
      <path
        d="M14.5,4h-9C4.7,4,4,4.7,4,5.5v9C4,15.3,4.7,16,5.5,16l0,0h9c0.8,0,1.5-0.7,1.5-1.5l0,0v-9C16,4.7,15.3,4,14.5,4z M5,8.8h10
	v2.3H5V8.8z M5.5,5h9C14.8,5,15,5.2,15,5.5v2.3H5V5.5C5,5.2,5.2,5,5.5,5z M14.5,15h-9C5.2,15,5,14.8,5,14.5v-2.3h10v2.3
	C15,14.8,14.8,15,14.5,15z"
      />
    </svg>
  )
}

export default IconViewList
