import React from "react"

import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react"

import { arrayIncludesObject } from "../../tools/filterForschung"
import IconChevronUp from "../Icons/IconChevronUp"
import IconChevronDown from "../Icons/IconChevronDown"

const FilterMenu = ({
  name,
  items,
  selectedFilterArray,
  setSelectedFilterArray,
  id,
}) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              isOpen ? (
                <Box width="24px" mt="3px" mr="-5px" ml="-5px">
                  <IconChevronUp />
                </Box>
              ) : (
                <Box width="24px" mr="-5px" ml="-5px">
                  <IconChevronDown />
                </Box>
              )
            }
            border="0px"
            colorScheme="gray"
            variant="ghost"
            color="black"
            borderColor="modeGray.100"
            bgColor="transparent"
            borderRadius="full"
            mb={["0px", "0px", "0px", "8px", "8px", "0px"]}
            // marginInlineStart={["0px", "0px", "0px", "0px", "0px", "0px"]}
          >
            {name}
          </MenuButton>
          <MenuList zIndex={50}>
            {items.map((item, index) => {
              return (
                <MenuItem
                  isDisabled={arrayIncludesObject(
                    selectedFilterArray,
                    item,
                    "value"
                  )}
                  key={index}
                  onClick={() => {
                    setSelectedFilterArray(oldSelectedFilterArray => [
                      ...oldSelectedFilterArray,
                      { name: id, value: item },
                    ])
                  }}
                >
                  {item}
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default FilterMenu
